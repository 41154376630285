import { render, staticRenderFns } from "./EventsPageV2.vue?vue&type=template&id=51f91f0a&scoped=true"
import script from "./EventsPageV2.vue?vue&type=script&lang=js"
export * from "./EventsPageV2.vue?vue&type=script&lang=js"
import style0 from "./EventsPageV2.vue?vue&type=style&index=0&id=51f91f0a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f91f0a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Arrow: require('/home/node/app/assets/icons/arrow.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,Caret: require('/home/node/app/assets/icons/caret.vue').default,ControllerCalendar: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerCalendar.js').default})
