
export default {
  data() {
    return {
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 20,
        centeredSlides: true,
        initialSlide: 3,
        loop: false,
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetweenSlides: 0,
          },
          500: {
            slidesPerView: 2,
            spaceBetweenSlides: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetweenSlides: 20,
          },
          1216: {
            slidesPerView: 4,
            spaceBetweenSlides: 20,
          },
          1440: {
            slidesPerView: 5,
            spaceBetweenSlides: 20,
          },
        },
      },

      options: [
        {
          name: 'picture',
          label: 'Picture',
          type: 'image',
        },
      ],
    }
  },
}
