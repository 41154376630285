
export default {
  data() {
    return {
      faq: [],
      form: {
        lastName: 'prezime',
        subject: 'naslov',
      },
      options: [
        {
          name: 'question',
          label: 'Pitanje',
          type: 'text',
        },
        {
          name: 'answer',
          label: 'Odgovor',
          type: 'tipTap',
        },
      ],
    }
  },
  methods: {
    fifth(index) {
      return index % 5
    },

    submitForm() {
      this.$toast.success(this.$i18n.t('contact.sent'))
    },

    openAnswer(index) {
      if (this.faq.includes(index)) {
        const faqIndex = this.faq.indexOf(index)
        this.faq.splice(faqIndex, 1)
      } else {
        this.faq.push(index)
      }
    },
  },
}
