
import moment from 'moment'
export default {
  data() {
    return {
      currentMonth: null,
      eventIndex: 0,
      eventImage: null,
    }
  },
  mounted() {
    this.currentMonth = moment(new Date()).format('MM')
    this.getFirstEvent()
  },
  methods: {
    getFirstEvent() {
      setTimeout(() => {
        const log = this.$refs.calendar
        if (!log || log.status.pending) {
          this.getFirstEvent()
          return
        }
        if (
          this.$refs.calendar &&
          this.$refs.calendar.events[this.eventIndex] &&
          this.$refs.calendar.events[this.eventIndex].images[0]
        ) {
          this.eventImage = this.$refs.calendar.events[
            this.eventIndex
          ].images[0]
        }
      }, 1500)
    },
    currentEvent(index) {
      if (
        this.$refs.calendar &&
        this.$refs.calendar.events[index] &&
        this.$refs.calendar.events[index].images[0]
      ) {
        this.eventImage = this.$refs.calendar.events[index].images[0]
      } else {
        this.eventImage = null
      }
    },
    month(post) {
      moment.locale(this.$i18n.locale)
      return moment(new Date()).format('MMMM')
    },
  },
}
