
export default {
  data() {
    return {
      options: [
        {
          name: 'picture',
          label: 'Slika',
          type: 'image',
        },
        {
          name: 'age',
          label: 'Uzrast',
          type: 'text',
        },
        {
          name: 'time',
          label: 'Vrijeme',
          type: 'text',
        },
        {
          name: 'color',
          label: 'Color',
          type: 'text',
        },
        {
          name: 'group',
          label: 'Skupina',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis',
          type: 'textarea',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
    }
  },
}
