
export default {
  data() {
    return {
      options: [
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: ['image-bg'],
      },
    }
  },

  mounted() {
    // setTimeout(() => {
    //   console.log(document.body)
    //   document.body.classList.add('image-bg')
    // }, 90)
  },
}
