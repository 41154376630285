
export default {
  asyncData({ app }) {
    return app.$api.get('article').then((article) => {
      app.$cache.route.setCacheable()
      return { article }
    })
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        autoplay: {
          delay: 4000,
        },
        paralax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },

      options: [
        {
          name: 'picture',
          label: 'Picture',
          type: 'image',
        },
        {
          name: 'title',
          label: 'Title',
          type: 'tipTap',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
    }
  },
}
