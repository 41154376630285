import { render, staticRenderFns } from "./KindergartenImages.vue?vue&type=template&id=e6d33122"
import script from "./KindergartenImages.vue?vue&type=script&lang=js"
export * from "./KindergartenImages.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Close: require('/home/node/app/assets/icons/close.vue').default,ArrowLeft: require('/home/node/app/assets/icons/arrow-left.vue').default,ArrowRight: require('/home/node/app/assets/icons/arrow-right.vue').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default})
