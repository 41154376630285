
import moment from 'moment'
// import SwiperCore, { Autoplay } from 'swiper'
// SwiperCore.use([Autoplay])
export default {
  data() {
    return {
      isEnd: false,
      isBeginning: true,
      showNoEvents: false,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        initialSlide: this.startSlide,
        loop: false,
        // autoplay: {
        //   delay: 4000,
        // },
        paralax: true,
        on: {
          slideChange: () => {
            this.setCurrentMonth()
          },
        },
        navigation: {
          prevEl: '.swiper-calendar-prev',
          nextEl: '.swiper-calendar-next',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      montsVisible: false,
      showSlider: false,
      currentMonth: null,
      activeMonth: null,
      currentMonthKey: null,
      selectedMonth: null,
      eventIndex: 0,
      eventImage: null,
    }
  },
  watch: {
    selectedMonth: {
      handler() {
        setTimeout(() => {
          if (
            this.$refs.calendar &&
            this.$refs.calendar.events.length &&
            this.$refs.calendar.events[0].images[0]
          ) {
            this.eventImage = this.$refs.calendar.events[0].images[0]
          }
        }, 1000)
      },
    },
  },
  mounted() {
    moment.locale('en')
    this.currentMonthKey = moment(new Date()).format('MMMM').toLowerCase()
    moment.locale(this.$i18n.locale)
    this.currentMonth = moment(new Date()).format('MMMM')
    this.selectedMonth = {
      key: this.currentMonthKey,
      name: this.currentMonth,
    }
    this.getFirstEvent()
  },
  methods: {
    setCurrentMonth() {
      if (this.$refs.calendar && this.heroSwiper) {
        console.log(
          this.$refs.calendar.events[this.heroSwiper.activeIndex].endEvent
        )
        this.activeMonth = moment(
          this.$refs.calendar.events[this.heroSwiper.activeIndex].endEvent
        ).format('MMMM')
      }
    },
    prev() {
      this.heroSwiper.slidePrev()
      if (this.heroSwiper.isEnd) {
        this.isEnd = true
      } else {
        this.isEnd = false
      }
      if (this.heroSwiper.isBeginning) {
        this.isBeginning = true
      } else {
        this.isBeginning = false
      }
    },
    next() {
      this.heroSwiper.slideNext()
      if (this.heroSwiper.isEnd) {
        this.isEnd = true
      } else {
        this.isEnd = false
      }
      if (this.heroSwiper.isBeginning) {
        this.isBeginning = true
      } else {
        this.isBeginning = false
      }
    },
    montsToggle() {
      this.montsVisible = !this.montsVisible
    },
    getFirstEvent() {
      setTimeout(() => {
        const log = this.$refs.calendar
        if (!log || log.status.pending) {
          this.getFirstEvent()
          return
        }
        if (this.$refs.calendar) {
          const now = Date.now()

          for (let i = 0; i < this.$refs.calendar.events.length; i++) {
            if (this.$refs.calendar.events[i].endEvent > now) {
              this.swiperOptions.initialSlide = i
              this.showSlider = true
              break
            } else {
              console.log('no events')
              this.showNoEvents = true
            }
          }
        }
      }, 1500)
    },
    currentEvent(index) {
      if (
        this.$refs.calendar &&
        this.$refs.calendar.events.length &&
        this.$refs.calendar.events[index].images[0]
      ) {
        this.eventImage = this.$refs.calendar.events[index].images[0]
      } else {
        this.eventImage = null
      }
    },
    changeMonth(month) {
      // this.$refs.heroSwiper.swiper.update()
      this.showSlider = true
      this.showNoEvents = false
      this.selectedMonth = month

      this.activeMonth = month.name
    },
    month(post) {
      moment.locale(this.$i18n.locale)
      return moment(new Date()).format('MMMM')
    },
  },
}
