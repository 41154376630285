var render, staticRenderFns
import script from "./Pedagogy.vue?vue&type=script&lang=js"
export * from "./Pedagogy.vue?vue&type=script&lang=js"
import style0 from "./Pedagogy.vue?vue&type=style&index=0&id=5ed9568c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ed9568c",
  null
  
)

export default component.exports