
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      activeItem: null,
      modal: false,
    }
  },
  watch: {
    modal(v) {
      if (v) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'auto'
      }
    },
  },
  methods: {
    openModal(v) {
      this.activeItem = v
      this.modal = true
    },
  },
}
