
export default {
  data() {
    return {
      playerState: false,
    }
  },
  methods: {
    playVideo() {
      if (process.browser) {
        const vid = document.querySelector('video')
        if (vid.paused) {
          vid.play()
          this.playerState = true
        } else {
          vid.pause()
          this.playerState = false
        }
      }
    },
  },
}
