
import moment from 'moment'
export default {
  data() {
    return {
      currentMonth: null,
      currentMonthKey: null,
      selectedMonth: null,
      eventIndex: 0,
      eventImage: null,
    }
  },
  watch: {
    selectedMonth: {
      handler() {
        setTimeout(() => {
          if (
            this.$refs.calendar &&
            this.$refs.calendar.events.length &&
            this.$refs.calendar.events[0].images[0]
          ) {
            this.eventImage = this.$refs.calendar.events[0].images[0]
          }
        }, 1000)
      },
    },
  },
  mounted() {
    moment.locale('en')
    this.currentMonthKey = moment(new Date()).format('MMMM').toLowerCase()
    moment.locale(this.$i18n.locale)
    this.currentMonth = moment(new Date()).format('MMMM')
    this.selectedMonth = {
      key: this.currentMonthKey,
      name: this.currentMonth,
    }
    this.getFirstEvent()
  },
  methods: {
    getFirstEvent() {
      setTimeout(() => {
        const log = this.$refs.calendar
        if (!log || log.status.pending) {
          this.getFirstEvent()
          return
        }
        if (
          this.$refs.calendar &&
          this.$refs.calendar.events[this.eventIndex] &&
          this.$refs.calendar.events[this.eventIndex].images[0]
        ) {
          this.eventImage = this.$refs.calendar.events[
            this.eventIndex
          ].images[0]
        }
      }, 1500)
    },
    currentEvent(index) {
      if (
        this.$refs.calendar &&
        this.$refs.calendar.events.length &&
        this.$refs.calendar.events[index].images[0]
      ) {
        this.eventImage = this.$refs.calendar.events[index].images[0]
      } else {
        this.eventImage = null
      }
    },
    changeMonth(month) {
      this.selectedMonth = month
    },
    month(post) {
      moment.locale(this.$i18n.locale)
      return moment(new Date()).format('MMMM')
    },
  },
}
