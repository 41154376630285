
import getVideoId from 'get-video-id'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import 'vue-lazy-youtube-video/dist/style.css'

export default {
  components: {
    LazyYoutubeVideo,
  },
  data() {
    return {
      videoID: null,
      inView: false,
    }
  },
  mounted() {
    if (this.$parent.componentData.linkMeta.videoId) {
      const url = this.$parent.componentData.linkMeta.videoId.replace(
        /(<([^>]+)>)/gi,
        ''
      )
      this.videoID = getVideoId(url)
    }
  },
}
