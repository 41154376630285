import { render, staticRenderFns } from "./HeroVideo.vue?vue&type=template&id=80767690"
import script from "./HeroVideo.vue?vue&type=script&lang=js"
export * from "./HeroVideo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,ControllerEvent: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js').default,Play: require('/home/node/app/assets/icons/play.vue').default})
