
import uiBox from '../ui/uiBox.vue'
export default {
  components: { uiBox },
  data() {
    return {
      options: [
        {
          name: 'name',
          label: 'Ime i prezime',
          type: 'text',
        },
        {
          name: 'title',
          label: 'Titula',
          type: 'text',
        },
        {
          name: 'picture',
          label: 'Slika',
          type: 'image',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
    }
  },
}
