import { render, staticRenderFns } from "./Teachers.vue?vue&type=template&id=3e23de80"
import script from "./Teachers.vue?vue&type=script&lang=js"
export * from "./Teachers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,UiBox: require('/home/node/app/components/ui/uiBox.vue').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default,Btn: require('/home/node/app/components/ui/btn.vue').default,EditorLink: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorLink.js').default})
