
import s0 from '~/.nuxt/gb-cms/sections/AllClasses'
import s1 from '~/.nuxt/gb-cms/sections/AllTeachers'
import s2 from '~/.nuxt/gb-cms/sections/Articles'
import s3 from '~/.nuxt/gb-cms/sections/Blog'
import s4 from '~/.nuxt/gb-cms/sections/BlogList'
import s5 from '~/.nuxt/gb-cms/sections/CtaClass'
import s6 from '~/.nuxt/gb-cms/sections/CtaContact'
import s7 from '~/.nuxt/gb-cms/sections/Classes'
import s8 from '~/.nuxt/gb-cms/sections/ContactForm'
import s9 from '~/.nuxt/gb-cms/sections/ContactFormCenter'
import s10 from '~/.nuxt/gb-cms/sections/ContactHome'
import s11 from '~/.nuxt/gb-cms/sections/EventSingle'
import s12 from '~/.nuxt/gb-cms/sections/Events'
import s13 from '~/.nuxt/gb-cms/sections/EventsPage'
import s14 from '~/.nuxt/gb-cms/sections/EventsPageV2'
import s15 from '~/.nuxt/gb-cms/sections/Faq'
import s16 from '~/.nuxt/gb-cms/sections/FaqContact'
import s17 from '~/.nuxt/gb-cms/sections/Fun'
import s18 from '~/.nuxt/gb-cms/sections/Gallery'
import s19 from '~/.nuxt/gb-cms/sections/GallerySlide'
import s20 from '~/.nuxt/gb-cms/sections/GalleryWhite'
import s21 from '~/.nuxt/gb-cms/sections/HeroEvent'
import s22 from '~/.nuxt/gb-cms/sections/HeroShapeVideo'
import s23 from '~/.nuxt/gb-cms/sections/HeroSlider'
import s24 from '~/.nuxt/gb-cms/sections/HeroVideo'
import s25 from '~/.nuxt/gb-cms/sections/ImageShapeText'
import s26 from '~/.nuxt/gb-cms/sections/ImageTextOffset'
import s27 from '~/.nuxt/gb-cms/sections/KindergartenImages'
import s28 from '~/.nuxt/gb-cms/sections/KindergartenSIngle'
import s29 from '~/.nuxt/gb-cms/sections/Kindergartens'
import s30 from '~/.nuxt/gb-cms/sections/Leader'
import s31 from '~/.nuxt/gb-cms/sections/LogoList'
import s32 from '~/.nuxt/gb-cms/sections/Memories'
import s33 from '~/.nuxt/gb-cms/sections/PageHero'
import s34 from '~/.nuxt/gb-cms/sections/PageHeroBox'
import s35 from '~/.nuxt/gb-cms/sections/Pedagogy'
import s36 from '~/.nuxt/gb-cms/sections/Podcast'
import s37 from '~/.nuxt/gb-cms/sections/ProductSingle'
import s38 from '~/.nuxt/gb-cms/sections/ProgramsAndProjects'
import s39 from '~/.nuxt/gb-cms/sections/Quote'
import s40 from '~/.nuxt/gb-cms/sections/ShapeText'
import s41 from '~/.nuxt/gb-cms/sections/Teachers'
import s42 from '~/.nuxt/gb-cms/sections/Testimonials'
import s43 from '~/.nuxt/gb-cms/sections/TextBulletsSection'
import s44 from '~/.nuxt/gb-cms/sections/TextBulletsSection2'
import s45 from '~/.nuxt/gb-cms/sections/TextCloud'
import s46 from '~/.nuxt/gb-cms/sections/TextImageShape'
import s47 from '~/.nuxt/gb-cms/sections/TextImageShapeWhite'
import s48 from '~/.nuxt/gb-cms/sections/TextShapeBottom'
import s49 from '~/.nuxt/gb-cms/sections/TextShapeMulti'
import s50 from '~/.nuxt/gb-cms/sections/TitleDescriptionTextSection'
import s51 from '~/.nuxt/gb-cms/sections/Video'
import s52 from '~/.nuxt/gb-cms/sections/Welcome'

const sectionMapper = {
	'all-classes': s0,
	'all-teachers': s1,
	'articles': s2,
	'blog': s3,
	'blog-list': s4,
	'cta-class': s5,
	'cta-contact': s6,
	'classes': s7,
	'contact-form': s8,
	'contact-form-center': s9,
	'contact-home': s10,
	'event-single': s11,
	'events': s12,
	'events-page': s13,
	'events-page-v-2': s14,
	'faq': s15,
	'faq-contact': s16,
	'fun': s17,
	'gallery': s18,
	'gallery-slide': s19,
	'gallery-white': s20,
	'hero-event': s21,
	'hero-shape-video': s22,
	'hero-slider': s23,
	'hero-video': s24,
	'image-shape-text': s25,
	'image-text-offset': s26,
	'kindergarten-images': s27,
	'kindergarten-s-ingle': s28,
	'kindergartens': s29,
	'leader': s30,
	'logo-list': s31,
	'memories': s32,
	'page-hero': s33,
	'page-hero-box': s34,
	'pedagogy': s35,
	'podcast': s36,
	'product-single': s37,
	'programs-and-projects': s38,
	'quote': s39,
	'shape-text': s40,
	'teachers': s41,
	'testimonials': s42,
	'text-bullets-section': s43,
	'text-bullets-section-2': s44,
	'text-cloud': s45,
	'text-image-shape': s46,
	'text-image-shape-white': s47,
	'text-shape-bottom': s48,
	'text-shape-multi': s49,
	'title-description-text-section': s50,
	'video': s51,
	'welcome': s52
}

export default sectionMapper
