
export default {
  data() {
    return {
      showMapList: false,
      form: {
        lastName: 'prezime',
        subject: 'naslov',
      },
      mapOptions: [
        {
          name: 'name',
          label: 'Location name',
          type: 'text',
        },
        {
          name: 'locationCoordinates',
          label: 'Coordinates',
          type: 'coordinates',
        },
      ],
      options: [
        {
          name: 'label',
          label: 'Naziv',
          type: 'text',
        },
        {
          name: 'value',
          label: 'Podatak',
          type: 'tipTap',
        },
      ],
    }
  },
}
