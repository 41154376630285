
export default {
  data() {
    return {
      playerState: false,
      lightBoxIndex: null,
      sidebarOptions: [
        {
          name: 'label',
          label: 'Label',
          type: 'text',
        },
        {
          name: 'value',
          label: 'Value',
          type: 'text',
        },
      ],
      options: [
        {
          name: 'image',
          label: 'Image',
          type: 'image',
        },
      ],
    }
  },
  mounted() {
    if (
      this.$parent.componentData.linkMeta &&
      this.$parent.componentData.linkMeta.gallery &&
      this.$parent.componentData.linkMeta.gallery.length
    ) {
      const gallery = this.$parent.componentData.linkMeta.gallery
      this.lightBoxIndex = gallery[0].image
    }
  },
  methods: {
    viewImage(index) {
      this.lightBoxIndex = index
    },
    playVideo() {
      if (process.browser) {
        const vid = document.querySelector('video')
        if (vid.paused) {
          vid.play()
          this.playerState = true
        } else {
          vid.pause()
          this.playerState = false
        }
      }
    },
  },
}
