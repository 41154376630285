import { render, staticRenderFns } from "./ContactHome.vue?vue&type=template&id=5463c4e8&scoped=true"
import script from "./ContactHome.vue?vue&type=script&lang=js"
export * from "./ContactHome.vue?vue&type=script&lang=js"
import style0 from "./ContactHome.vue?vue&type=style&index=0&id=5463c4e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5463c4e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorMap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,ControllerFooter: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js').default})
