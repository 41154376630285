
export default {
  data() {
    return {
      lightBoxIndex: 0,
      share: false,
    }
  },
  mounted() {
    if (
      this.$parent.componentData.linkMeta &&
      this.$parent.componentData.linkMeta.gallery &&
      this.$parent.componentData.linkMeta.gallery.length
    ) {
      const gallery = this.$parent.componentData.linkMeta.gallery
      this.lightBoxIndex = gallery[0].image
    }
  },
  methods: {
    viewImage(index) {
      this.lightBoxIndex = index
    },
  },
}
