
export default {
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'subtitle',
          label: 'Podnaslov',
          type: 'textarea',
        },
        {
          name: 'description',
          label: 'Opis',
          type: 'tipTap',
        },
      ],
    }
  },
}
