
export default {
  data() {
    return {
      lightBoxIndex: null,
      options: [
        {
          name: 'image',
          label: 'Image',
          type: 'image',
        },
      ],
    }
  },
  methods: {
    viewImage(index) {
      this.lightBoxIndex = index
    },
  },
}
