
export default {
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis',
          type: 'text',
        },
        {
          name: 'picture',
          label: 'Slika',
          type: 'image',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
    }
  },
}
