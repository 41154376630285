import { render, staticRenderFns } from "./GalleryWhite.vue?vue&type=template&id=641255d0&scoped=true"
import script from "./GalleryWhite.vue?vue&type=script&lang=js"
export * from "./GalleryWhite.vue?vue&type=script&lang=js"
import style0 from "./GalleryWhite.vue?vue&type=style&index=0&id=641255d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641255d0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,EditorListItem: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js').default,Close: require('/home/node/app/assets/icons/close.vue').default,ArrowLeft: require('/home/node/app/assets/icons/arrow-left.vue').default,ArrowRight: require('/home/node/app/assets/icons/arrow-right.vue').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default})
