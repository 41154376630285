import { render, staticRenderFns } from "./ContactFormCenter.vue?vue&type=template&id=5c93ece6&scoped=true"
import script from "./ContactFormCenter.vue?vue&type=script&lang=js"
export * from "./ContactFormCenter.vue?vue&type=script&lang=js"
import style0 from "./ContactFormCenter.vue?vue&type=style&index=0&id=5c93ece6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c93ece6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorMap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorMap.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default,ControllerFooter: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerFooter.js').default,UiInput: require('/home/node/app/components/ui/uiInput.vue').default,UiTextarea: require('/home/node/app/components/ui/uiTextarea.vue').default,Btn: require('/home/node/app/components/ui/btn.vue').default,ControllerContactForm: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerContactForm.js').default})
