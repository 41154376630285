
export default {
  data() {
    return {
      options: [
        {
          name: 'group',
          label: 'Skupina',
          type: 'text',
        },
        {
          name: 'age',
          label: 'Uzrast',
          type: 'text',
        },
        {
          name: 'circleColor',
          label: 'Circle color',
          type: 'text',
        },
        {
          name: 'bgcolor',
          label: 'Background color',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Opis',
          type: 'textarea',
        },
      ],
    }
  },
}
