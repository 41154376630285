import { render, staticRenderFns } from "./AllTeachers.vue?vue&type=template&id=1e8aa540&scoped=true"
import script from "./AllTeachers.vue?vue&type=script&lang=js"
export * from "./AllTeachers.vue?vue&type=script&lang=js"
import style0 from "./AllTeachers.vue?vue&type=style&index=0&id=1e8aa540&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8aa540",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InView: require('/home/node/app/components/ui/inView.vue').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,UiBoxModal: require('/home/node/app/components/ui/uiBoxModal.vue').default,EditorListItem: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default})
