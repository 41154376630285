
export default {
  data() {
    return {
      activeItem: null,
      modal: false,
      inView: false,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 20,
        centeredSlides: true,
        initialSlide: 2,
        loop: false,
        pagination: {
          el: '.testimonials-pagination',
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetweenSlides: 0,
          },
          768: {
            slidesPerView: 2,
            spaceBetweenSlides: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetweenSlides: 20,
          },
        },
      },
      options: [
        {
          name: 'description',
          label: 'Poruka',
          type: 'textarea',
        },
        {
          name: 'name',
          label: 'Ime i prezime',
          type: 'text',
        },
        {
          name: 'position',
          label: 'Pozicija',
          type: 'text',
        },
      ],
    }
  },
  watch: {
    // modal(v) {
    //   if (v) {
    //     document.body.style.overflowY = 'hidden'
    //   } else {
    //     document.body.style.overflowY = 'auto'
    //   }
    // },
    $isInEditMode(v) {
      if (v) {
        this.slideSwiper.slideTo(0, 800, false)
      } else {
        this.slideSwiper.slideTo(1, 800, false)
      }
    },
  },
  methods: {
    openModal(v) {
      this.activeItem = v
      this.modal = true
    },
  },
}
