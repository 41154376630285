
export default {
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        // autoplay: {
        //   delay: 4000,
        // },
        // paralax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      options: [
        {
          name: 'picture',
          label: 'Picture',
          type: 'image',
        },
        {
          name: 'title',
          label: 'Title',
          type: 'tipTap',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
      topLogoOptions: [
        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
        },
      ],
      logoOptions: [
        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
          size: '100x400',
        },
      ],
    }
  },
}
