import { render, staticRenderFns } from "./ProductSingle.vue?vue&type=template&id=8fc9709e&scoped=true"
import script from "./ProductSingle.vue?vue&type=script&lang=js"
export * from "./ProductSingle.vue?vue&type=script&lang=js"
import style0 from "./ProductSingle.vue?vue&type=style&index=0&id=8fc9709e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fc9709e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerProductConfiguration: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProductConfiguration.js').default,Btn: require('/home/node/app/components/ui/btn.vue').default,Share: require('/home/node/app/assets/icons/share.vue').default,Twitter: require('/home/node/app/assets/icons/twitter.vue').default,Facebook: require('/home/node/app/assets/icons/facebook.vue').default,UiProduct: require('/home/node/app/components/ui/uiProduct.vue').default,ControllerRelatedProducts: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerRelatedProducts.js').default,ControllerProduct: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerProduct.js').default})
