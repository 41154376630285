var render, staticRenderFns
import script from "./GalleryWhite.vue?vue&type=script&lang=js"
export * from "./GalleryWhite.vue?vue&type=script&lang=js"
import style0 from "./GalleryWhite.vue?vue&type=style&index=0&id=d92d997e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d92d997e",
  null
  
)

export default component.exports