
export default {
  data() {
    return {
      itemsPerPage: 12,
      currentPage: 1,
      itemsCount: [],
      lightBoxIndex: null,
      options: [
        {
          name: 'picture',
          label: 'Picture',
          type: 'image',
        },
      ],
    }
  },
  methods: {
    viewImage(index) {
      this.lightBoxIndex = index
    },
    page(n) {
      this.currentPage = n
      location.href = '#top'
    },
  },
}
