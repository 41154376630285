
import getVideoId from 'get-video-id'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import 'vue-lazy-youtube-video/dist/style.css'
export default {
  components: {
    LazyYoutubeVideo,
  },
  data() {
    return {
      videoID: null,
      video: null,
      autoplay: 0,
      options: [
        {
          name: 'link',
          label: 'Video URL',
          type: 'link',
        },
        {
          name: 'title',
          label: 'Video naslov',
          type: 'text',
        },
        {
          name: 'picture',
          label: 'Video poster',
          type: 'image',
        },
      ],
    }
  },
  mounted() {
    if (
      this.$parent.componentData.linkMeta.videos &&
      this.$parent.componentData.linkMeta.videos.length
    ) {
      const url =
        this.$parent.componentData.linkMeta.videos[0].link.href.replace(
          /(<([^>]+)>)/gi,
          ''
        )
      this.videoID = getVideoId(url)
    }
  },
  methods: {
    playVideo(item) {
      this.video = item
      if (item.link && item.link.href) {
        const url = item.link.href.replace(/(<([^>]+)>)/gi, '')
        this.videoID = getVideoId(url)
        this.autoplay = 1
        if (process.browser) {
          document.getElementById('videotop').scrollIntoView({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }
      }
    },
  },
}
