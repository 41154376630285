import { render, staticRenderFns } from "./HeroShapeVideo.vue?vue&type=template&id=661cbf07&scoped=true"
import script from "./HeroShapeVideo.vue?vue&type=script&lang=js"
export * from "./HeroShapeVideo.vue?vue&type=script&lang=js"
import style0 from "./HeroShapeVideo.vue?vue&type=style&index=0&id=661cbf07&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661cbf07",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorImage: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorImage.js').default,EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,Play: require('/home/node/app/assets/icons/play.vue').default,EditorListItem: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorListItem.js').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default})
