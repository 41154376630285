
export default {
  data() {
    return {
      faq: [0],
      options: [
        {
          name: 'question',
          label: 'Pitanje',
          type: 'text',
        },
        {
          name: 'answer',
          label: 'Odgovor',
          type: 'tipTap',
        },
      ],
    }
  },
  methods: {
    openAnswer(index) {
      if (this.faq.includes(index)) {
        const faqIndex = this.faq.indexOf(index)
        this.faq.splice(faqIndex, 1)
      } else {
        this.faq.push(index)
      }
    },
  },
}
