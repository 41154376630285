
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    labelFor: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },

    errorMessage: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
}
