
export default {
  data() {
    return {
      inView: true,
      itemsPerPage: 12,
      currentPage: 1,
      itemsCount: [],
      options: [
        {
          name: 'name',
          label: 'Ime i prezime',
          type: 'text',
        },
        {
          name: 'title',
          label: 'Titula',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Description',
          type: 'textarea',
        },
        {
          name: 'picture',
          label: 'Slika',
          type: 'image',
        },
        {
          name: 'link',
          label: 'Link',
          type: 'link',
        },
      ],
    }
  },
  watch: {
    $isInEditMode: {
      handler(val) {
        if (val) {
          this.itemsPerPage = 99999
        } else {
          this.itemsPerPage = 6
        }
      },
    },
  },
  methods: {
    page(n) {
      this.currentPage = n
      location.href = '#top'
    },
  },
}
