
export default {
  data() {
    return {
      showMapList: false,
      mapOptions: [
        {
          name: 'name',
          label: 'Location name',
          type: 'text',
        },
        {
          name: 'locationCoordinates',
          label: 'Coordinates',
          type: 'coordinates',
        },
      ],
    }
  },
}
