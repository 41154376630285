import { render, staticRenderFns } from "./EventSingle.vue?vue&type=template&id=31064b11&scoped=true"
import script from "./EventSingle.vue?vue&type=script&lang=js"
export * from "./EventSingle.vue?vue&type=script&lang=js"
import style0 from "./EventSingle.vue?vue&type=style&index=0&id=31064b11&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31064b11",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditorTipTap: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorTipTap.js').default,Twitter: require('/home/node/app/assets/icons/twitter.vue').default,Facebook: require('/home/node/app/assets/icons/facebook.vue').default,EditorList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/editors/EditorList.js').default,ControllerEvent: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerEvent.js').default})
